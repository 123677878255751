import React, { useEffect, useLayoutEffect, useRef } from "react";
import { styled } from "styled-components";
import { IoLogoGooglePlaystore, IoStarOutline } from "react-icons/io5";

import colors from "../config/colors";
import NavBar from "../components/NavBar";
import SectionTitle from "../components/SectionTitle";
import JoinUsSection from "../sections/JoinUsSection";
import MessageSection from "../sections/MessageSection";
import Footer from "../components/Footer";
import logo from "../assets/icons/loftime.webp";
import googlePlay from "../assets/google-play.webp";
import CardBgColor from "../components/CardBgColor";
import { animate } from "../utils/loadAnimation";
import AppBtnAlt from "../components/AppBtnAlt";
import smallPlanet from "../assets/commonImg/bigBlackBall.webp";
import bigPlanet from "../assets/commonImg/bigBlackBall.webp";
import Slider from "react-slick";
import { useSearchParams } from "react-router-dom";
import gridImg from "../assets/commonImg/hero-grid.webp"
import greenGradBg from '../assets/commonImg/greenGradientBG.webp'
import { productList, soonProductList } from "../utils/constants";

const ProductPage = () => {
  const rootRef = useRef();
  const appsSliderRef = useRef();
  const [searchParam] = useSearchParams();
  const appUrl = searchParam.get("app");


  useLayoutEffect(() => {
    let ctx = animate(rootRef, ".section-title");
    let ctx3 = animate(rootRef, ".subtTitle");
    let ctx2 = animate(rootRef, ".app-section");
    let ctxlr = animate(appsSliderRef, "#taskwask", "leftToRight");

    return () => {
      ctx.revert();
      ctx2.revert();
      ctx3.revert();
      ctxlr.revert();

    };
  }, []);

  useEffect(() => {
  const app = document.getElementById(appUrl); 
  //function to scroll to specific app if came from home
    const scrollToApp = () => { 
        const offset = 220; // Amount of pixels to reduce by
        const targetScrollPosition = app.getBoundingClientRect().top + window.scrollY - offset;
        window.scrollTo({ top: targetScrollPosition, behavior: "smooth" });}
   if (app) scrollToApp();
   else window.scrollTo(0, 0); 
   // eslint-disable-next-line
  }, []);
  return (
    <Container ref={rootRef}>
      <NavBar />
      <div className="topWrapper">
      <img src={greenGradBg} alt="greenGradBg" style={{position: 'absolute', top: 0, right: "16%", width: '50%', height: '50%', zIndex: 0}} />
      <div className="top-section app-padding">
        <img src={smallPlanet} alt="smallPlanet" className="small-planet" />
        <img src={bigPlanet} alt="bigPlanet" className="big-planet" />
        <SectionTitle isSabo={true} center={true} className='section-title' >
          The <img src={logo} alt="logo" /> App Inventory
        </SectionTitle>
        <p className="subtTitle">
          Play, Engage, and Conquer Tasks: Once you've selected your desired
          app, it's time to embark on an exhilarating journey of task
          completion. Immerse yourself in the app's captivating features,
          challenge yourself with puzzles, achieve milestones & start your
          unique journey to earnings.
        </p>
      </div>
      </div>
      <div className="apps-section" ref={appsSliderRef}>
        {productList.map((app,i) => (
          <div className={`app-section app-padding animate${String(i+1)}`} key={app?.id} id={app?.id} >
            <div className="image-con">
              <img src={app.img} alt={app.appName} />
            </div>
            <div className="app-right">
              <div className="name-link">
                <img src={app.appIcon} alt={app.appName} />
                <div>
                  <p className="app-name">{app.appName}</p>
                  <p className="app-stats">
                    {app.stars} <IoStarOutline /> {app.downloads}
                    <IoLogoGooglePlaystore />
                  </p>
                </div>

                <img
                  onClick={() => window.open(app?.link, "_blank")}
                  src={googlePlay}
                  alt="google-play-store"
                  className="playStoreIcon cursor"
                />
              </div>

              <div className="app-desc">{app.description}</div>
              <div className="offerings">
                {app.offerings.map((offering, i) => (
                  <CardBgColor className="offering-card" key={i}>
                    <img src={offering?.icon} alt="hand-phone" />
                    <p className="o-title">{offering.title}</p>
                    <p className="o-subtitle">{offering.description}</p>
                  </CardBgColor>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>

      <SectionTitle center className="section-title">
        More<span> Rewards & Apps </span>coming soon...
      </SectionTitle>

    <div className="sliderSec">
    <Slider
      autoplay
				dots
				infinite
				speed={500}
				autoplaySpeed={3000}
				slidesToShow={1}
				slidesToScroll={1}
				className="appCard"
    >
        {soonProductList.map((item) => (
          <AppCard key={item?.appName} className="swipeApp" >
            <img src={item?.img} alt="appIcon1" className="app-banner" />
            <img src={item?.appIcon} alt="appIcon1" className="app-icon" />
            <h3>{item?.appName}</h3>
            <AppBtnAlt style={{margin:'0 auto'}}>Coming Soon</AppBtnAlt>
          </AppCard>
        ))}
      </Slider>
</div>
      <div className="moreapp-section">
        {soonProductList.map((item) => (
          <div key={item?.appName} className="appCard" id={item?.id}>
          <div className="appImgSec">
            <img src={item?.appIcon} alt="appIcon1" className="app-icon" />
            <img src={item?.img} alt="appIcon1" className="app-banner" />
          </div>
            <h3>{item?.appName}</h3>
            <AppBtnAlt>Coming Soon</AppBtnAlt>
          </div>
        ))}
      </div>

      <JoinUsSection />
      <MessageSection />

      {/* <MessageSection style={{ paddingTop: 40 }} /> */}

      <Footer />
    </Container>
  );
};

const Container = styled.div`
  background: ${colors.black};
  min-height: 100vh;
  color: ${colors.white};
  overflow: hidden;
  position: relative;
  .moreapp-section{
    display: none;
  }
  .topWrapper{
    background: url(${gridImg});
    background-size: 200px;
    background-repeat: repeat;
    position: relative;
    padding-bottom: 120px;

  }
  .top-section {
    min-height: 300px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 154px;
    overflow: hidden;
    
    .small-planet {
      position: absolute;
      top: 6vw;
      left: 14vw;
      height: 46.509px;
      width: 43.88px;
    }

    .big-planet {
      position: absolute;
      right:8vw;
      top: 5vw;
      padding: 0;
      margin: 0;
      bottom: 22%;
      opacity: 0.5;
      z-index: 0;
      height: 130px;
    }
    .section-title {
      margin-bottom: 30px;
    }
    p {
      width: 80%;
      text-align: center;
    }
  }
  .apps-section {
    margin-bottom: 154px;
    position: relative;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    gap: 120px;
    overflow: hidden;
    .app-section {
      ${'' /* position: absolute;
			top: 0;
			left: 0;  */}
      width: 100%;
      overflow: hidden;
      height: fit-content;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 110px;
      &:nth-child(odd) .app-right {
        order: -1;
      }
      .image-con {
        width: 580px;
        height: 480px;
        overflow: hidden;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          margin: 0 auto;
        }
      }
      .app-right {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .name-link {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          gap: 20px;
          img:first-of-type {
            height: 60px;
            border-radius: 10px;
          }
          img:last-of-type {
            height: 50px;
            margin: auto;
          }
          div {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            .app-name {
              font-size: 18px;
            }
            .app-stats {
              color: grey;
              display: flex;
              align-items: center;
              svg {
                margin: 0 10px 0 5px;
              }
            }
          }
        }
        .app-desc {
          opacity: 0.7;
          font-weight: 200;
          line-height: 25px;
          letter-spacing: 0.4px;
        }
        .offerings {
          margin-top: 30px;
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
          gap: 35px;
          .offering-card {
            padding: 40px 20px 20px;
            position: relative;
            img {
              box-sizing: content-box;  
              background:linear-gradient(to bottom, #1f1f1f, #171717);
              height: 32px;
              width:32px;
              padding:8px 12px;
              border-radius: 10px;
              position: absolute;
              top: 0;
              transform: translateY(-50%);
              &:before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 10px;
        padding: 1.5px;
        background: linear-gradient(to top, #171717 15%, #3f3f3f);
        -webkit-mask: linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
      }
            }
            .o-subtitle {
              margin-top: 5px;
              opacity: 0.7;
              font-weight: 200;
              font-size: 14px;
              line-height: 23px;
              letter-spacing: 0.4px;
            }
          }
        }
      }
    }
  }
  .section-title {
      margin-bottom: 80px;
    }
  @media screen and (max-width: 1314px) {
    .apps-section{
      .image-con {
        max-width: 100%;
        height: 100%;
      
      }

    }

  }
  @media screen and (max-width:1024px) {
    .image-con  {
      max-width:90vw;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
    }
    .playStoreIcon{
      width: 140px;
      height: auto;
    }
   .apps-section{
    .app-section{
      &:nth-child(odd) .app-right {
        order: -1;
      
      }
      &:nth-child(even) .app-right {
        order: -1;
      
      }
    }
   }
  }
  @media screen and (min-width: 850px) {
   .sliderSec{
    display: none;
   }
   .moreapp-section{
    margin-bottom: 154px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px 1.6vw;
    justify-content: center;
    overflow: hidden;
    .appCard {
      display: flex;
      flex-direction: column;
      align-items: center;
      .appImgSec{
        position: relative;
        .app-banner {
        border-radius: 20px;
      }
      .app-icon {
        height: 4.6vw;
        border-radius: 20px;
        position: absolute;
        bottom:-2.4em;
        left: 36%;
      }
      }
     
      h3 {
        color: #f9f9f9;
        text-align: center;
        font-family: Lexend;
        font-size: 34px;
        font-style: normal;
        font-weight: 400;
        line-height: 52px;
        letter-spacing: 0.34px;
        margin: 70px 0 5px 0;
      }
    }
  }
  }
`;

export default ProductPage;


const AppCard = styled.div`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      padding-bottom:154px;
      .app-banner {
        border-radius: 20px;
        margin: 0 auto;
      }
      .app-icon {
        height: 80px;
        border-radius: 20px;
        position: absolute;
        padding: 0 auto;
        bottom: 46%;
        left: 38%;
      }
      h3 {
        color: #f9f9f9;
        text-align: center;
        font-family: Lexend;
        font-size: 34px;
        font-style: normal;
        font-weight: 400;
        line-height: 52px;
        letter-spacing: 0.34px;
        margin: 70px 0 5px 0;
      }
  @media screen and (max-width: 900px) {
    .app-icon {
        left: 44vw;

      }
  }
  @media screen and (max-width: 485px) {
    .app-icon {
        left: 40vw;
      }
  }
`