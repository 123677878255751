import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { IoCloseCircle, IoMenu } from 'react-icons/io5';
import  LemonIcon from '../assets/icons/lemonIcon.svg';

import AppBtn from './AppBtn';
import colors from '../config/colors';

const navLinks = [
	{ url: '/', label: 'Home' },
	{ url: '/products', label: 'Products' },
	{ url: '/rewards', label: 'Rewards' },
	{ url: '/youtuber', label: 'Youtubers' },
	{ url: '/about', label: 'About' },
];

const NavBar = () => {
	const location = useLocation();

	const [scrollStarted, setScrollStarted] = useState(false);

	const [navOpened, setNavOpened] = useState(false);
	const openMobNav = () => setNavOpened(true);
	const closeMobNav = () => setNavOpened(false);

	useEffect(() => {
		const scrollYListener = ()=>{
				setScrollStarted(window.scrollY >= 50 ? true : false);
		}
		window.addEventListener('scroll', scrollYListener);

		return () => window.removeEventListener('scroll', scrollYListener);
		
	}, []);

	return (
		<>
			<Container
				// className="nav-padding"
				navOpened={navOpened}
				scrollStarted={scrollStarted}
			>
				<div className="inner">
					<span className="burger" onClick={openMobNav}>
						<IoMenu />
					</span>
					<div className="links">
						{navLinks
							.map((link, i) => (
								<Link key={i} to={link.url}>
								<span className={`${link?.url === location.pathname.toLocaleLowerCase() && 'active'}`}>{link?.url === '/' ? <img src={LemonIcon} width={20} height={20} alt='lemonIcon'/> :''} {link.label}</span>
								</Link>
							))}
					</div>

					<AppBtn onClick={()=>window.open('https://loftlime.com/partnerwithus', '_blank')}className='title' >Let's Talk</AppBtn>
				</div>
			</Container>

			<MobNav navOpened={navOpened}>
				<IoCloseCircle onClick={closeMobNav} />
				{navLinks
					.filter(
						(link) => link.url !== location.pathname.toLowerCase()
					)
					.map((link, i) => (
						<Link key={i} to={link.url}>
							{link.label}
						</Link>
					))}
			</MobNav>
		</>
	);
};

const MobNav = styled.div`
	position: fixed;
	background: ${colors.green};
	top: 0;
	left: 0;
	z-index: 20;
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 20px 0;
	gap: 20px;
	transition: all 0.2s linear;
	transform: ${({ navOpened }) =>
		navOpened ? 'translateY(0)' : 'translateY(-100%)'};
	overflow: hidden;
	svg {
		cursor: pointer;
		font-size: 30px;
		margin-bottom: 30px;
		transition: all 0.2s linear;
		&:hover {
			transform: scale(1.1);
		}
	}
	a {
		font-size: 25px;
		border-bottom: 2px solid transparent;
		opacity: 0.6;
		transition: all 0.2s linear;
		&:hover {
			border-bottom: 2px solid #fff;
			opacity: 1;
		}
	}
`;

const Container = styled.nav`
	position: fixed !important;
	top: 0;
	left: 0;
	right: 0;
	z-index: 10;
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	transition: all 0.1s linear;
	background: ${({ scrollStarted }) =>
		scrollStarted ? 'rgba(42, 161, 76, 0.1)' : 'rgba(42, 161, 76, 0)'};
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: ${({ scrollStarted }) =>
		scrollStarted ? 'blur(13.9px)' : 'blur(0px)'};
	-webkit-backdrop-filter: ${({ scrollStarted }) =>
		scrollStarted ? 'blur(13.9px)' : 'blur(0px)'};
	
	.title{
		font-weight: 600;
	}
	.inner {
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		padding: 20px 0;
		gap: 20px;
	}
	.links {
		display: flex;
		gap: 30px;
		font-weight: 300;
		span{
			display: flex;
			align-items: center;
			gap: 5px;
			cursor: pointer;
			font-size: 18px;
			font-weight: 600;
			&.active{
				color:${colors.green};
				padding-bottom: 4px;
				border-bottom:4px solid ${colors.green};
			}
		}
	}
	.burger {
		width: 40px;
		height: 40px;
		cursor: pointer;
		border-radius: 4px;
		display: none;
		align-items: center;
		justify-content: center;
		font-size: 30px;
		border: 0.5px solid transparent;
		transition: all 0.2s linear;
		&:hover {
			border: 0.5px solid #ddd;
		}
	}

	/* .nav-padding { */
	padding: 0 80px;
	/* } */

	@media screen and (max-width: 1000px) {
		padding: 0 50px;
	}
	@media screen and (max-width: 700px) {
		padding: 0 30px;
	}
	@media screen and (max-width: 600px) {
		.links {
			display: none;
		}
		.burger,
		.mob-links {
			display: flex;
		}
	}

	@media screen and (max-width: 500px) {
		padding: 0 13px;
	}

	@media screen and (min-width: 1380px) {
		.inner {
			max-width: 1380px;
			margin: auto;
		}
	}
`;

export default NavBar;
