import React, { useEffect } from "react";
import styled from "styled-components";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import colors from "../config/colors";

const PrivacyPolicy = () => {
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
  return (
    <Container>
      <NavBar />
      <div className="terms-section app-padding">
      <h1>Privacy Policy</h1>

<h2>1. Introduction</h2>

<p>Welcome aboard our rewarding platform! We are happy to introduce you to LoftLime (referred to as " We " & " Us " & " Our "in this Policy), a product by LeadMint Technologies Pvt. Ltd. ( the “ Company “). This Privacy Policy will assist you to understand how LeadMint Technologies & LoftLime gather and utilise the data of a “ user “ ( referred to here as " You " & “ Your “) through our official apps and website. Without your consent, the features of LoftLime will not be accessible to you. LeadMint Technologies Pvt Ltd built the LoftLime app inventory as ad-supported apps. This Service is provided by LeadMint Technologies at no cost and is intended for use as described in our Privacy Policy. By using the Service, you agree to the collection and use of information under this policy.</p>
<p>Our privacy policy may be updated or modified at any time without prior notification. As a user, kindly check the privacy policy and the modifications made to it regularly. This Privacy Policy is uniformly applicable to the LoftLime apps and website. Here are some common references that we would use throughout this Policy:</p>

          <h3><span className="h3b">1.1 Client:</span> <span>  Refers to a third-party business that partners with LoftLime to provide its services. A ' client ' may also be classified as “ they “ & “ them “, an entity that collaborates with LeadMint Technologies to upscale its business strategy. You can refer to their official Privacy Policies to know how they function independently. </span></h3>
          <h3><span className="h3b">1.2 User:</span> <span> Refers to an individual who uses the LoftLime apps and website to avail of its rewarding benefits. </span></h3>
          <h3><span className="h3b">1.3 Company:</span> <span> Refers to a business/entity registered lawfully by the government. </span></h3>
          <h3><span className="h3b">1.4 Website:</span> <span> The official landing page or web page of a Company that can be accessed by any random user through search engines.</span></h3>
          <h3><span className="h3b">1.5 Application:</span> <span> Also referred to as an ' App ' is a software program that is designed to perform a specific task. Applications can be downloaded on a device through app stores or available links on search engines. </span></h3>
          <h3><span className="h3b">1.6 App Inventory:</span> <span> Refers to the collection of applications installed on a user's device. This may include information such as the names of installed apps, version numbers, and usage data. The app may collect app inventory data to personalize user experiences, provide targeted advertisements, or improve app performance. This information is treated as personal data and is subject to the same privacy protections outlined in this policy. Users have the right to control the collection and usage of their app inventory data through the app's privacy settings.</span></h3>
          <h3><span className="h3b">1.7 Personal data:</span> <span> Quite specifically, it is a collective term for the sensitive details of an Individual. For example, their Name, Address, Phone number, Email address, Date of Birth, Employment Status, Educational status and online activity. </span></h3>
          <h3><span className="h3b">1.8 Privacy:</span> <span> It is a fundamental human right that is strictly exercised in most countries. In simple words, Privacy is YOUR right to control your sensitive information. You can also decide who can see your info, who can use it and to what extent is it shared. </span></h3>
          <h3><span className="h3b">1.9 Consent:</span> <span> When you voluntarily agree to engage in an activity by using an app or website, you must provide your ' Consent ' for the same.</span></h3>
          <h3><span className="h3b">1.10 Cookies:</span> <span> Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory. This Service does not use these “cookies” explicitly. However, the app may use third-party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.</span></h3>


<h2>2. The information that we collect:</h2>

<p>LoftLime collects and stores the data that you willingly provide. This includes your:</p>
<ul>
    <li>Full Name: that you fill up during your registration process on the LoftLime app.</li>
    <li>Phone Number: to approve your cash incentives into your Paytm Wallet.</li>
    <li>Email Address: so that you can receive important updates from our team or reach out to us in case of any queries.</li>
    <li>Gender: to segregate your data into a systematic format.</li>
    <li>Date of Birth: to personalise your advertising experience according to your age.</li>
    <li>Interests: We record your responses on the official LoftLime apps through Polls, Surveys, and Tasks that you complete.</li>
    <li>Employment Status/Educational Qualifications: So that we can display relevant tasks to you.</li>
    <li>Cookies: to track your activities on our website.</li>
    <li>Geographic Location: to centralize all our marketing strategies for our clients.</li>
    <li>IP address: So that our advertisers can piece together a profile of your interests.</li>
</ul>

<h2 style={{fontSize:"22px"}}>Here's the data that LoftLime does NOT collect from you:</h2>
<ul>
    <li>Your social media accounts' activity</li>
    <li>Your Social Media accounts' engagement history</li>
    <li>Your Browsing History</li>
    <li>Your Medical information</li>
    <li>Your Clickstream Data</li>
    <li>Your Passwords</li>
    <li>Your Credit Card information</li>
</ul>

<p>This action is implemented under the following laws:</p>

<h3><span className="h3b"> The Information Technology Act, 2000 (IT Act):</span> <span> Section 43A of the IT Act states that no person shall, without the consent of the person concerned, collect, store, retain, use, or disclose any personal information in electronic form. However, this section does allow for the collection of personal information without consent in certain cases, such as when the information is necessary for the performance of a contract or to provide a service to the user.</span></h3>
<h3><span className="h3b">The Privacy (Protection) of Electronic Communication Rules, 2011 (SPDI Rules):</span> <span> The SPDI Rules further elaborate on the provisions of the IT Act and provide additional safeguards for the protection of personal information. These rules require that websites and apps obtain the consent of users before collecting any personal information and that they provide users with the right to access, correct, and delete their personal information.</span></h3>

<h3>2.1. What is “Data Collection”?</h3>
<p>Data collection is a process of gathering a user's information that is provided to LoftLime with the consent of the user. This Data Collection method is known as Explicit Consent. It is presumably the most legit way for Data Collection. Explicit Consent means that when a user wants to browse through the LoftLime apps, they must provide their consent and agree to the Terms of Service & Privacy Policy of LoftLime. Henceforth, without your consent, the LoftLime team would not be able to receive your personal data. Furthermore, you would not be able to avail the features available on the LoftLime app. </p>
<h3>2.2 Information Collection and Usage</h3>
<p>For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to Name, Email, Mobile Number for coin update and DeviceID to uniquely identify the device, Installed package information to provide incent offers. The information that We request will be only used to provide in-app incent offers.</p>
<p>Link to the privacy policy of third-party service providers used by the app</p>
<ul>
    <li><a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">Google Play Services</a></li>
    <li><a href="https://support.google.com/admob/answer/6128543?hl=en" target="_blank" rel="noreferrer">AdMob</a></li>
    <li><a href="https://www.facebook.com/privacy/policy/?entry_point=data_policy_redirect&entry=0" target="_blank" rel="noreferrer">Facebook</a></li>
    <li><a href="https://www.ayetstudios.com/privacy-policy" target="_blank" rel="noreferrer">AyetStudio</a></li>
    <li><a href="https://onesignal.com/privacy_policy" target="_blank" rel="noreferrer">Onesignal</a></li>
    <li><a href="https://adjoe.io/privacy/" target="_blank" rel="noreferrer">Adjoe</a></li>
    <li><a href="https://www.applovin.com/privacy/" target="_blank" rel="noreferrer">AppLovin</a></li>
</ul>
<p>We value your privacy. This information will not be sold, shared, or used in any way outside of the standard operation of the application.</p>

<h2>3. How do we utilise your data?</h2>

<p>LoftLime team utilise your information to:</p>

<ul>
    <li>Leverage your user experience by modifying the current LoftLime apps and LoftLime website design.</li>
    <li>Create compelling blogs to be published on our website, apps, and social media handles.</li>
    <li>Allow our advertising partners to run advertising campaigns on the LoftLime apps.</li>
    <li>Apart from the users' Full name, Phone number, Email address, IP address, and DOB, the other data gathered by LoftLime is shared with our clients so that they can leverage their marketing strategies.</li>
</ul>

<h3>3.1 Duration of Data Storage:</h3>
<p>Your data will be deleted by us as soon as it is no longer necessary to achieve the purpose for which it was collected. In addition, your data will be deleted if you revoke your consent or request the deletion of your personal data.</p>

<h2>4. What are your rights?</h2>

<p>To protect user privacy and consent, the Indian government has declared the following user rights:</p>

<ul>
    <li>Right to information</li>
    <li>Right to Access</li>
    <li>Right to Correct</li>
</ul>
<h3><span className="h3b">4.1. Right to information:</span> <span>You have the right to information regarding the collection, use, and disclosure of your personal data, which is known as the right to information. Users should be able to easily understand this information, which should be presented in a clear and succinct manner.</span></h3>
<h3><span className="h3b">4.2. Right to Access:</span> <span>Users have the right of access, which entitles them to view their personal data that is kept by a business or organisation. This includes the right to know what data was gathered, how it was put to use, and with whom it was shared.</span></h3>
<h3><span className="h3b">4.3. Right to Correct:</span> <span>Users have the right to correct any errors in their personal information that are kept by a business or organisation, which is known as the right to correct.</span></h3>
<h3><span className="h3b">4.4. Right to Withdraw Consent:</span> <span>The ability to revoke consent: Users are granted the freedom to stop approving the gathering, use, and disclosure of their personal data at any time.</span></h3>
<h3><span className="h3b">4.5. Right to Delete:</span> <span>The term "right to delete" refers to the ability of users to request the removal of their personal data from a company's or organisation's files.</span></h3>
<p>The ability to file a complaint with the government: If users feel that their privacy rights have been abused, they have the right to do so.</p>
<h3><span className="h3b">The Information Technology Act of 2000 (IT Act):</span> <span> and the Information Technology (Reasonable Security Practises and Procedures and Sensitive Personal Data or Information) Rules of 2011 both contain provisions that protect these rights. The IT Act also specifies penalties for businesses or organisations that breach users' rights to privacy.</span></h3>


<h2>5. Children’s Privacy</h2>

<p>Only people aged 13 or older have permission to access our Service. Our Service does not address anyone under the age of 13 ("Children") and an app age gate has been added to ensure only people aged 13 or older can use our Service.</p>
<p>We do not knowingly collect personally identifiable information from children under 13. If you are a parent or guardian and you learn that your Children have provided us with Personal Information, please contact us. If we become aware that we have collected Personal Information from children under age 13 without verification of parental consent, we take steps to remove that information from our servers.</p>

<h2>6. Contact info & method</h2>

<p>We trust that this privacy statement has helped to clarify how we collect, use, share, and safeguard your personal data. Please get in touch with us if you have any inquiries or worries. You can contact us at: support@loftlime.com By utilizing LoftLime's services, you are indicating your acceptance and agreement with our policies. Your seamless experience is based on this understanding.</p>

      </div>
      <Footer />
    </Container>
  );
};

export default PrivacyPolicy;
const Container = styled.div`
  background-color: #130f18;
  color: ${colors.white};
  a{
    text-decoration: underline;
    font-weight: 200;
  }
  .links{
    a{
        text-decoration: none;
    }
  }
  .terms-section {
    padding-top: 100px;
    padding-bottom: 154px;
    color: #fff;
    li {
        margin-left: 4%;
    }
    h1 {
      margin-top: 20px;
      margin-bottom: -10px;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
    }
    h2 {
      margin-top: 80px; 
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
    }
    h3 {
      margin-top: 30px; 
      font-size: 22px;
      font-style: normal;
      font-weight: 300;
      line-height: 32px;
      .h3b{
        font-weight: 300;
        font-size: 16px;
        text-decoration: underline;
      }
      span{
        margin-top: 20px; 
      font-size: 16px;
      font-style: normal;
      font-weight: 100;
      line-height: 32px;
      }
    }
    p,ul{
      margin-top: 20px; 
      font-size: 16px;
      font-style: normal;
      font-weight: 100;
      line-height: 32px;
    }
  }
`;
