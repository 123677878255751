import React, { useEffect } from "react";
import styled from "styled-components";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import colors from "../config/colors";

const TermsPage = () => {
  useEffect(()=>{
      window.scrollTo(0,0)
  },[])
  return (
    <Container>
      <NavBar />
      <div className="terms-section app-padding">
        <h1>Terms of service</h1>
        <h2>1. Introduction to terms of service:</h2>
        <p>LoftLime (the website and app inventory) is a digital productdeveloped and regulated by LeadMint Technologies Pvt. Ltd.(collectively referred to as “the company”, “We”, “Us” & “Our”). Forcollaborative conduct, it is important for every user to read,understand and agree to the Terms of Service (referred to as “terms”)mentioned below. If a user (herein “You”, or “Your”) does not agreewith the terms, they may choose not to use the LoftLime website. Tocontinue using the website, you must provide your consent and agree tothe terms of service listed below.</p>
        <h2 style={{fontSize:"22px", marginTop:'50px'}}>Definitions of important terms:</h2>
        <p>Here are some common references that we would use throughout thisagreement:</p>
        <h3><span className="h3b">1.1 Client:</span><span>  Refer to a third-party business that partners with LoftLime toprovide its services. A ' client ' may also be classified as “ they“ & “ them “, an entity that collaborates with LeadMint Technologiesto upscale its business strategy. You can refer to their officialPrivacy Policies to know how they function independently.</span></h3>
        <h3> <span className="h3b">1.2 User:</span><span> Refers to an individual who uses the LoftLime website and apps toavail of its rewarding benefits.</span></h3>
        <h3><span className="h3b">1.3 Services:</span><span> Herein all the features available to the users of the LoftLimewebsite and apps.</span></h3>
        <h3><span className="h3b">1.4 Rewards:</span><span> Are the monetary and non-monetary incentives provided to the userswho use the services of LoftLime.</span></h3>
        <h3><span className="h3b">1.5 Intellectual properties (IP):</span><span> Often referred to as “IP”, these are the exclusive logos, colourschemes, imagery, and taglines that are protected by Copyright,Patent, and trademark under the governing laws.</span></h3>
        <h3><span className="h3b">1.6 Personal data:</span><span> Quite specifically, it is a collective term for the sensitive  details of an Individual. For example, their Name, Address, Phone  number, Email address, Date of Birth, Employment Status, Educational  status, and online activity.</span></h3>
        <h3><span className="h3b">1.7 Privacy:</span><span> It is a fundamental right that is strictly exercised in most  countries. In simple words, Privacy is YOUR right to control your  sensitive information. You can also decide who can see your info,  who can use it and to what extent is it shared.</span>
        </h3>
        <h3><span className="h3b">1.8 Consent:</span><span> When you voluntarily agree to engage in an activity by using an app  or website, you must provide your ' Consent ' for the same.</span>
        </h3>
        <h3><span className="h3b">1.9 Copyright:</span><span> This is a governing law that grants exclusivity to protect artistic  works such as software, taglines, blogs, etc.</span>
        </h3>
        <h3><span className="h3b">1.10 Trademark:</span><span> Is a governing law that protects the logo, exclusive imagery,  designs, and symbols.</span>
        </h3>
        <h3><span className="h3b">1.11 Patent:</span><span> An exclusive right to confidentially use the products, software, and  processes.</span>
        </h3>
        <h3><span className="h3b">1.12 Trade Security Rights:</span><span> Under the governing laws, a company can protect its confidential  business information.</span>
        </h3>
        <h2>2. Grant of Licence:</h2>
        <p> Several features, services, trademarked, patented, and copyrightedcontent are lawfully protected by LoftLime. Here are some crucialpermissions and their limitations set by the company. You must go through these sections and provide your consent for the same.</p>
        <h3>2.1 Permissions:</h3>
        <p>By using the LoftLime website and apps, you agree to certainpermissions allowed to you. Here is a list of permissions granted bythe company that is regulated and modified by us:</p>
        <ul>
          <li>
            You are allowed to use the Services of the LoftLime website and
            apps.
          </li>
          <li>
            You are allowed to disclose your personal data that is secured by
            the LoftLime team.
          </li>
          <li>
            You are allowed to participate in the ongoing events, trending
            offers, and other events conducted on the website, apps, as well as
            the social media handles of LoftLime.
          </li>
          <li>
            You are allowed to customize your user experience by adjusting
            preferences and settings within the app or website.
          </li>
          <li>
            You are allowed to receive notifications, updates, and
            communications related to your account, rewards, and promotions.
          </li>
          <li>
            You are allowed to provide feedback, reviews, and ratings to help
            improve the LoftLime experience for yourself and other users.
          </li>
          <li>
            You are allowed to share content generated within the LoftLime
            ecosystem on social media platforms, subject to our terms of use and
            community guidelines.
          </li>
          <li>
            You are allowed to access and manage your personal data in
            accordance with applicable data protection laws and regulations.
          </li>
          <li>
            You are allowed to exercise your rights regarding data privacy,
            including the right to access, rectify, erase, or restrict the
            processing of your personal data.
          </li>
        </ul>
        <h3>2.2 Limitations</h3>
        <p>Here are the limitations that are implemented on you regarding thefeatures and creative elements in LoftLime. It must be noted thatthese limitations are set forth to safeguard the confidentiality ofthe users and the company.</p>
        <ul>
          <li>
            As a user, you are not allowed to use, copy, redesign, or share the
            copyrighted logo, imagery, and tagline of the LoftLime website in
            any way.
          </li>
          <li>
            Miscellaneous activities and inappropriate behavior can lead to the
            termination of your account.
          </li>
          <li>
            You may not use the LoftLime website or apps in a way that
            interferes with the rights of others, including their intellectual
            property rights.
          </li>
          <li>
            You are prohibited from engaging in fraudulent activities, including
            but not limited to, creating multiple accounts, using unauthorized
            payment methods, or exploiting loopholes in the system.
          </li>
          <li>
            You are not permitted to reverse engineer, decompile, or modify any
            part of the LoftLime website or apps.
          </li>
          <li>
            You are prohibited from engaging in any form of hacking, data
            mining, or unauthorized access to the LoftLime servers or systems.
          </li>
          <li>
            You are not allowed to distribute or transmit any harmful code,
            viruses, or malware through the LoftLime website or apps.
          </li>
          <li>
            You are expected to comply with all applicable laws and regulations
            while using LoftLime services.
          </li>
        </ul>
        <p>You can also refer to the Terms of Services of our clients who haveprovided third-party integration in the LoftLime website. Namely, theyare<a href="https://policies.google.com/privacy">Google Play Services</a>,<a href="https://support.google.com/admob/answer/6128543?hl=en">  AdMob</a>,<a href="https://www.facebook.com/privacy/policy/?entry_point=data_policy_redirect&entry=0">  Facebook</a>, <a href="https://www.ayetstudios.com/privacy-policy">AyetStudio</a>,<a href="https://onesignal.com/privacy_policy">Onesignal</a>,<a href="https://adjoe.io/privacy/">Adjoe</a>, and<a href="https://www.applovin.com/privacy/">AppLovin</a>. Thesethird-party businesses help to provide more features, services, andrelevant ads to you.</p>
        <h2>3. Intellectual Property Rights:</h2>
        <h3>
          <span className="h3b">3.1</span>  <span>  The LoftLime website and all of its
          content, including text, images, videos, software, and designs, are
          the property of LoftLime and LeadMint Technologies Pvt. Ltd. All
          rights granted are reserved. </span></h3>
        <h3>
          <span className="h3b">3.2</span>  <span>  You may not copy, distribute, modify,
          or create derivative works of any content of the company without the
          express written permission of LoftLime. You may not use any LoftLime
          trademarks or logos without the express written permission of the
          authorities of LoftLime. </span></h3>
        <h3>
          <span className="h3b">3.3</span>  <span>  If you post any content to the
          LoftLime website, you grant LoftLime a non-exclusive, royalty-free,
          perpetual, irrevocable, and fully sublicensable right to use,
          reproduce, modify, adapt, publish, translate, distribute, perform, and
          display such content in any media or format throughout the world. </span></h3>
        <h3>
          <span className="h3b">3.4</span>  <span>  You represent and warrant that you
          own or have the necessary rights to post the content you submit to the
          LoftLime website or apps, and that posting such content does not
          violate the rights of any third party. </span></h3>
        <h3>
          <span className="h3b">3.5</span> <span>  LoftLime reserves the right to remove
          any content from the LoftLime website or apps at any time for any
          reason without prior notification. By using the LoftLime website or
          apps, you agree to abide by the LoftLime Intellectual Property Rights
          policy. If you violate this policy, LoftLime may take any action it
          deems appropriate, including but not limited to terminating your
          account and/or blocking your access to the LoftLime website. </span></h3>
        <h2>4. User Consent & Privacy:</h2>
        <p>As also mentioned under the Privacy Policy guidelines, LoftLime cannot get your personal data without your prior consent. This action is implemented under the following laws: </p>
        <h3><span className="h3b"><u>4.1 The Information Technology Act, 2000 (IT Act):</u></span> <span>
     Section 43A of the IT Act states that no person shall, without the consent
    of the person concerned, collect, store, retain, use, or disclose any
    personal information in electronic form. However, this section does allow
    for the collection of personal information without consent in certain cases,
    such as when the information is necessary for the performance of a contract
    or to provide a service to the user.
  </span>
</h3>
<h3>
  <span className="h3b"><u>4.2 The Privacy (Protection) of Electronic Communication Rules, 2011 (SPDI Rules):</u> </span>
  <span>
     The SPDI Rules further elaborate on the provisions of the IT Act and provide
    additional safeguards for the protection of personal information. These rules
    require that websites and apps obtain the consent of users before collecting
    any personal information and that they provide users with the right to access,
    correct, and delete their personal information.
  </span>
</h3>


        <h2>5. Termination of ToS:</h2>
        <p>LoftLime may terminate your account and/or your access to the LoftLime website at any time for any reason, including but not limited to the following: </p>
        <ul>
  <li>If you violate any of the terms of this agreement;</li>
  <li>If you engage in any activity that LoftLime deems to be harmful, illegal, or unethical;</li>
  <li>If LoftLime ceases to offer the LoftLime website;</li>
  <li>If your account is terminated, you will no longer be able to use the LoftLime website and your account information will be deleted.</li>
</ul>

        <h2>6. Severability:</h2>
        <p>
          If any provision of these Terms of Service is held to be invalid or
          unenforceable, such provision shall be struck from these Terms of
          Service and the remaining provisions shall remain in full force and
          effect.
        </p>
        <p>The users agree that if any provision of these Terms of Service is held to be invalid or unenforceable, the parties will negotiate in good faith to modify such provision to achieve the approximate economic effect of the invalid or unenforceable provision. If the parties are unable to agree on a modification, such provision shall be struck from these Terms of Service and the remaining provisions shall remain in full force and effect.</p>
        <p>This severability provision shall apply to all provisions of these Terms of Service, including this severability provision.</p>
        <h2>7. Contact info & method:</h2>
        <p>
          We trust that this Terms of Service statement has helped to clarify
          how we collect, use, and regulate your personal data and Intellectual
          Properties. Please get in touch with us if you have any inquiries or
          worries. You can contact us at: support@loftlime.com
        </p>
        <p>
          Your faith and trust in us are much appreciated, and we will keep
          working hard to improve your user experience. By utilizing LoftLime's
          Services, you are indicating your acceptance and agreement with our
          policies. Your seamless experience is based on this understanding.
        </p>
      </div>
      <Footer />
    </Container>
  );
};

export default TermsPage;
const Container = styled.div`
  background-color: #130f18;
  color: ${colors.white};
  a{
    text-decoration: underline;
    font-weight: 200;
  }
  .links{
    a{
        text-decoration: none;
    }
  }
  .terms-section {
    padding-top: 100px;
    padding-bottom: 154px;
    color: #fff;
    li {
        margin-left: 4%;
    }
    h1 {
      margin-top: 20px;
      margin-bottom: -10px;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
    }
    h2 {
      margin-top: 80px; 
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
    }
    h3 {
      margin-top: 30px; 
      font-size: 22px;
      font-style: normal;
      font-weight: 300;
      line-height: 32px;
      .h3b{
        font-weight: 300;
        font-size: 16px;
        text-decoration: underline;
      }
      span{
        margin-top: 20px; 
      font-size: 16px;
      font-style: normal;
      font-weight: 100;
      line-height: 32px;
      }
    }
    p,ul{
      margin-top: 20px; 
      font-size: 16px;
      font-style: normal;
      font-weight: 100;
      line-height: 32px;
    }
  }
`;